<template>
<div>
  <rel-header class="hidden-print">
    <form>
      <e-row mr>
        <e-col>
          <e-row class="large-mr erp-s-field-bold" mr>
            <e-col style="max-width: 140px">
              <erp-s-field label="Data inicial:">
                <datetime-modern-input v-model="filters.data1" date-type="date" date-format="##/##/####" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 140px">
              <erp-s-field label="Data final:">
                <datetime-modern-input v-model="filters.data2" date-type="date" date-format="##/##/####" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="Pessoa:">
                <global-pessoa-input v-model="filters.colaborador" layout="float-icon input-hide-loading" :hide-underline="false" />
              </erp-s-field>
            </e-col>
          </e-row>
        </e-col>
        <e-col style="max-width: 120px; text-align: right;" class="flex items-center justify-end">
          <erp-s-field label="">
            <u-btn label="Gerar" color="green" :loading="gerando" @click="gerar" />
          </erp-s-field>
        </e-col>
      </e-row>
    </form>
  </rel-header>
  <div v-if="resultado" class="sl-body">
    <div class="base-table">
      <table>
        <thead>
        <tr>
          <th style="max-width: 160px; width: 160px"><span>Data</span></th>
          <th class=""><span>Ação</span></th>
          <th class=""><span>Pessoa</span></th>
          <th class=""><span>Log</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="log in resultado.result">
          <td style="max-width: 160px; width: 160px" class="text-nowrap">{{log.date|formatDate('dd/MM/yyyy HH:mm:ss')}}</td>
          <td>{{log.action}}</td>
          <td>
            <div v-if="log.personId" @click="clickPerson({id: log.personId})" class="no-wrap text-nowrap cursor-pointer">
              {{log.person}}
            </div>
          </td>
          <td style="white-space: break-spaces">{{log.message}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import RelHeader from '@/components/relatorios/layout/RelHeader'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {ErpSField} from "uloc-vue-plugin-erp"
import DatetimeModernInput from "@/reuse/input/DatetimeModern"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import {list} from "@/domain/logs/service/log"
import {datePtToEn} from "@/utils/date"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
import {formatDistance} from "date-fns"
import ptBRLocale from "date-fns/locale/pt-BR"

export default {
  name: "LogOperacional",
  mixins: [GlobalPersonMixin],
  data () {
    return {
      cache: {
        timestamp: null
      },
      fake: {
      },
      filters: {
        data1: null,
        data2: null,
        colaborador: null
      },
      gerando: false,
      resultado: null
    }
  },
  methods: {
    gerar () {
      const filters = JSON.parse(JSON.stringify(this.filters))
      this.$uloc.loading.show({message: 'Gerando relatório'})
      this.gerando = true
      let formato = 'json'
      const filtrosUrl = []
      filters.colaborador = filters.colaborador && filters.colaborador.id ? filtrosUrl.push('pessoa=' + filters.colaborador.id) : null
      filters.data1 ? filtrosUrl.push('data1=' + datePtToEn(filters.data1)) : null
      filters.data2 ? filtrosUrl.push('data2=' + datePtToEn(filters.data2)) : null
      list(100, 1, filtrosUrl.join('&'), formato)
          .then(response => {
            this.$uloc.loading.hide()
            this.gerando = false
            if (formato === 'json' && response && response.data) {
              this.resultado = response.data
            }
          })
          .catch(error => {
            this.gerando = false
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    clickPerson (person) {
      this.gotoPerson(person)
    },
    convertDate (d) {
      return d.replace(/(\d{4})(\d{2})(\d{2})/, '$3/$2/$1')
    },
    secondsToHours (s) {
      return formatDistance(0, s * 1000, {includeSeconds: true, locale: ptBRLocale})
    }
  },
  components: {
    GlobalPessoaInput,
    DatetimeModernInput,
    ErpSField,
    ECol,
    ERow,
    RelHeader,
  }
}
</script>
