import http from "@/utils/services/http"

export const logBuffer = (action, entity, entityId) => {
    let url = `/api/log/find`
    return http.get(url + `?action=${action}&entity=${entity}&entityId=${entityId}`)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const list = (limit, page, filtros, format = null) => {
    let url = '/api/op/log?page=' + page + '&limit=' + limit + '&' + filtros
    let config = {}
    /*if (format && format !== 'html') {
        config.responseType = 'blob'
        config.timeout = 30000
    }*/
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
